<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                        <form
                            class="box"
                            @submit.prevent="login"
                        >
                            <div class="login-title">
                                Papermill Preview Login
                            </div>
                            <section class="fields">
                                <div class="field">
                                    <div class="control has-icons-left">
                                        <input
                                            v-model="email"
                                            type="email"
                                            placeholder="Email address"
                                            class="input"
                                            required
                                        >
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon icon="fa-light fa-envelope" />
                                        </span>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="control has-icons-left">
                                        <input
                                            v-model="password"
                                            type="password"
                                            placeholder="Password"
                                            class="input"
                                            required
                                        >
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon icon="fa-light fa-lock" />
                                        </span>
                                    </div>
                                </div>
                            </section>
                            <div class="field button-container">
                                <button
                                    class="button is-primary"
                                    type="submit"
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {auth} from '@/db.js'
import {toast} from 'bulma-toast'

// TODO: add vee validate

export default {
    name: 'PapermillLogin',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        login() {
            // Hook for user update will redirect us
            auth.signInWithEmailAndPassword(this.email, this.password)
                .then(() => {
                    this.$router.push({name: 'Home'})
                })
                .catch(() => {
                    toast({
                        message: 'Login failed: please check your email and password',
                        duration: 3000,
                        position: 'bottom-center'
                    })
                })
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    opacity: 0.75;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hero {
    background-image: url('../assets/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
section.fields {
    margin-top: 2rem;
    margin-bottom: 2rem;
    .control {
        margin-bottom: 1rem;
    }
    .icon {
        color: darkslategray;
    }
}
.login-title {
    font-weight: bold;
    text-align: center;
}
.button-container {
    display: flex;
    justify-content: center;
}
</style>
