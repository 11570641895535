import { mergeAttributes, Node } from '@tiptap/core'
import {TextSelection} from 'prosemirror-state'

export default Node.create({
    name: 'figcaption',
    content: 'inline*',
    selectable: false,
    draggable: false,
    isolating: true,
    code: true,
    addOptions() {
        return {
            exitOnTripleEnter: true,
            exitOnArrowDown: true,
        }
    },
    parseHTML() {
        return [
            {
                tag: 'figcaption',
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return [
            'figcaption', mergeAttributes(HTMLAttributes),
            ['div', {class: 'figure-caption-prefix'}, '',],
            ['div', {class: 'caption'}, 0]
        ]
    },
    addKeyboardShortcuts() {
        return {
            'mod-a': function({editor}) {
                // Check that selection is within the same figure caption
                let fromParent = editor.view.state.selection.$from.parent
                let toParent = editor.view.state.selection.$to.parent
                let isFigureCaption = fromParent.type.name === 'figcaption'
                if (fromParent === toParent && isFigureCaption) {
                    const from = editor.view.state.selection.$from.pos - editor.view.state.selection.$from.parentOffset
                    const to = from + editor.view.state.selection.$from.parent.nodeSize
                    editor.commands.setTextSelection({from, to})
                    return true
                }
            },

            // Triple enter will delete the empty lines, insert a paragraph , and jump out of the figure
            Enter: ({ editor }) => {
                if (!this.options.exitOnTripleEnter) {
                    return false
                }
                const {state} = editor
                const {$head, $from, empty} = state.selection
                if (!empty || $from.parent.type !== this.type) {
                    return false
                }
                const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2
                const endsWithDoubleNewline = $from.parent.textContent.endsWith('\n\n')
                if (!isAtEnd || !endsWithDoubleNewline) {
                    return false
                }
                let pos = $head.after()
                return editor.chain()
                    .command(({tr}) => {
                        tr.insert(pos, editor.schema.nodes.paragraph.createAndFill(null))
                        tr.setSelection(TextSelection.near(tr.doc.resolve(pos)))
                        tr.delete($from.pos - 2, $from.pos)
                        tr.scrollIntoView()
                        return true
                    })
                    .run()
            }
        }
    }
})
