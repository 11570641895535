import {VueRenderer} from '@tiptap/vue-2'
import tippy from 'tippy.js'
import store from '@/store/'
import MentionList from '@/components/MentionList.vue'
import Fuse from 'fuse.js'

export default {
    items: ( {query} ) => {
        let anchorMap = store.getters.anchorMap
        const anchors = Object.values(anchorMap)
        if (query) {
            const fuse = new Fuse(anchors, {keys:['label']})
            const res = fuse.search(query)
            return res.map(r => r.item)
        } else {
            return anchors
        }
    },

    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new VueRenderer(MentionList, {
                    parent: this,
                    propsData: props,
                })
                if (!props.clientRect) {
                    return
                }
                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)
                if (!props.clientRect) {
                    return
                }
                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()
                    return true
                }
                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}
