// Firebase
// v9 compat packages are API compatible with v8 code, we need them for firebaseui
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

//this is now default for local development
//when pushed this will be replaced by
//deployments/db.dev.js for dev deployment
//deployments/db.staging.js for staging deployment
//deployments/db.prod.js for production deployment
//this is the cleanest solution i can think of so far

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

//default to point to dev db
const firebaseConfig = {
    apiKey: 'AIzaSyBM1OIwqv7mY4r9KzqV1twcwqH-sPVJ5p4',
    authDomain: 'papermill-dev-a5ece.firebaseapp.com',
    projectId: 'papermill-dev-a5ece',
    storageBucket: 'papermill-dev-a5ece.appspot.com',
    messagingSenderId: '274110124960',
    appId: '1:274110124960:web:65cb3cc729baf55cd44ac5'
}

export const fb = firebase.initializeApp(firebaseConfig)

export const auth = fb.auth()
export const db = fb.firestore()

export default {fb, auth, db}