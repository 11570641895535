<template>
    <node-view-wrapper class="pm-table-wrapper">
        <div>
            <NodeViewContent as="table" />
            <button
                class="button delete-button"
                @click="deleteTable"
            >
                <span class="icon">
                    <font-awesome-icon
                        icon="fa-regular fa-trash-can"
                        size="lg"
                    />
                </span>
            </button>
            <div class="width-container">
                <div class="width-label">
                    Width (% of text width)
                </div>
                <div class="width-slider-container">
                    <vue-slider
                        :value="sliderValue"
                        :lazy="true"
                        :tooltip-formatter="tooltipFormatter"
                        @change="changeWidth"
                    />
                </div>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import {mapState} from 'vuex'
import 'firebase/compat/storage'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    props: {
        node: {
            type: Object,
            required: true,
        },
        updateAttributes: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            sliderValue: undefined,
            tooltipFormatter: '{value}%',
        }
    },
    computed: {
        width() {
            return this.node.attrs.layoutWidth
        },
        ...mapState(['currentDocument'])
    },
    components: {
        NodeViewContent,
        NodeViewWrapper,
        VueSlider
    },
    watch: {
        width: {
            handler(newWidth) {
                this.sliderValue = newWidth
            },
            immediate: true
        }
    },
    methods: {
        deleteTable() {
            this.deleteNode()
            let pos = this.editor.state.doc.resolve(this.getPos())
            for (let depth = pos.depth; depth > 0; depth -= 1) {
                const node = pos.node(depth)  // get its parent, <shrug>
                if (node.type.name === 'tableFigure') {
                    const from = pos.before(depth)
                    const to = pos.after(depth)
                    this.editor.commands.deleteRange({from, to})
                }
            }
        },
        //tr.delete(from, to).scrollIntoView()
        changeWidth(layoutWidth) {
            this.updateAttributes({
                layoutWidth
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.pm-table-wrapper {
    position: relative;
}
.delete-button {
    position: absolute;
    right: -2rem;
    top: -1rem;
    border: none;
    background-color: transparent;
    color: darkgrey;
    display: none;
}
.delete-button:active {
    color: grey;
}
</style>
<style lang="scss">
.width-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.width-label {
    font-family: Lato, sans-serif;
    font-size: 0.7rem;
    margin-bottom: -0.5rem;
}
.vue-slider-process {
    background-color: $primary !important;
}
.vue-slider-dot-handle {
    border-color: $primary !important;
}
.vue-slider-dot-handle-focus {
    box-shadow: 0 0 0 5px rgba($primary, .2); //rgb(54 171 255 / 20%)
}
</style>
<style scoped>
.table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
