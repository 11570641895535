<template>
    <node-view-wrapper
        as="td"
        class="pm-table-cell-wrapper"
    >
        <PapermillTablePopupMenu
            class="popup"
            :editor="editor"
        />
        <NodeViewContent />
    </node-view-wrapper>
</template>

<script>
import {mapState} from 'vuex'
import 'firebase/compat/storage'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2'
import 'vue-slider-component/theme/antd.css'
import PapermillTablePopupMenu from '@/components/PapermillTablePopupMenu'

export default {
    props: {
        node: {
            type: Object,
            required: true,
        },
        editor: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['currentDocument'])
    },
    components: {
        NodeViewContent,
        NodeViewWrapper,
        PapermillTablePopupMenu
    },
    watch: {
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.popup {
    display: none;
}

.pm-table-cell-wrapper:hover .popup {
    display: block;
}
</style>
