import { mergeAttributes, Node } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

export const Figure = Node.create({
    name: 'figure',
    draggable: false,
    selectable: true,
    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },
    group: 'block',
    content: 'block figcaption',
    isolating: true,
    parseHTML() {
        return [
            {
                tag: `figure[data-type="${this.name}"]`,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return ['figure', mergeAttributes(HTMLAttributes, { 'data-type': this.name }), 0]
    },

    addProseMirrorPlugins() {
        const { editor } = this
        return [
            new Plugin({
                props: {
                    handleKeyDown: function handleKeyDown(view, event) { //view, event) {
                        if (event.key === 'Backspace') {
                            let node = view.state.selection.$anchor.node()
                            if (node.type.name === 'figcaption') {
                                return false
                            } else {
                                // hardcoded - need to fix this
                                editor.commands.deleteNode('imageFigure')
                            }
                            return false
                        }
                    },
                },
            }),
        ]
    },
})
