import {Editor} from '@tiptap/vue-2'
import Bold from '@tiptap/extension-bold'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import Code from '@tiptap/extension-code'
import CollaborationExtension from './extensions/CollaborationExtension'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Document from '@tiptap/extension-document'
import DropCursor from '@tiptap/extension-dropcursor'
import Focus from '@tiptap/extension-focus'
import GapCursor from '@tiptap/extension-gapcursor'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import UniqueID from '@tiptap-pro/extension-unique-id'

import {HocuspocusProvider} from '@hocuspocus/provider'

// Codeblocks with highlighting
import { lowlight } from './extensions/codeblocklangs'

const randomColor = require('randomcolor')

import Crossref from './extensions/crossref'
import PMListItem from './extensions/pmlistitem'
import PMMention from './extensions/pmmention'
import PMImage from './extensions/pmimage'
import Figcaption from './extensions/figcaption.js'

import {
    BulletList,
    CodeBlock,
    ImageFigure,
    OrderedList,
    Paragraph,
    Table,
    TableCell,
    TableHeader,
    TableFigure
} from './extensions/pmnodes'
import Heading from './extensions/heading'
import TrackTop from './extensions/tracktop'
import {debounce} from './utils'

const allTypes = [
    'bulletList',
    'codeBlock',
    'figcaption',
    'heading',
    'image',
    'imageFigure',
    'listItem',
    'mention',
    'orderedList',
    'paragraph',
    'tableFigure'
]

import {updateHandler} from './editor_utils'

// To get auth token
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const serverUrl = 'wss://papermill-dev-a5ece.nw.r.appspot.com/'
// const serverUrl = 'ws://127.0.0.1:8080'


/***
 * Hack to compensate for a bug in ProseMirror.
 * When doing a "hot reload" of a new document, we get this problem:
 * https://github.com/ueberdosis/tiptap/issues/1451#issuecomm ent-953348865
 * In that thread, this fix is suggested.
 */
import { EditorView } from 'prosemirror-view'
import {toast} from 'bulma-toast'
// import {PluginKey} from "prosemirror-state";
EditorView.prototype.updateState = function updateState(state) {
    if (!this.docView) return // This prevents the matchesNode error on hot reloads
    this.updateStateInner(state, this.state.plugins != state.plugins)
}

export async function createEditor(docId, user) {

    const token = await
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        return idToken
    }).catch(function(error) {
        console.err('Error getting firebase id token: ' + error)
    })

    // Set up the Hocuspocus WebSocket provider
    const provider = new HocuspocusProvider({
        url: serverUrl,
        name: docId,
        token,
        onClose(config) {
            if (config.event.code === 4403) {
                toast({
                    message: `Error opening document: ${config.event.reason}`,
                    duration: 3000,
                    position: 'bottom-center'
                })
            }
        },
    })

    const initials = user.name.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('').toUpperCase()

    const editor = new Editor({
        autofocus: 'end',
        onUpdate: debounce((args) => updateHandler(args), 100),
        extensions: [
            Bold,
            BubbleMenu.configure({
                pluginKey: 'bubbleMenuOne',
                element: document.querySelector('.bubble')
            }),
            BulletList,
            Code.configure({
                HTMLAttributes: {
                    class: 'inline-code',
                },
            }),
            CodeBlock.configure({
                lowlight,
                HTMLAttributes: {spellcheck: 'false'}
            }),
            Document,
            Figcaption,
            Focus,
            GapCursor,
            ImageFigure,
            Italic,
            Link,
            OrderedList,
            Paragraph,
            PMListItem,
            Text,
            Typography,
            Underline,
            CollaborationExtension.configure({document: provider.document}),
            CollaborationCursor.configure({provider, user: {
                name: user.name,
                papermillUserId: user.uid,
                initials,
                color: randomColor({
                    luminosity: 'light',
                    format: 'hex' // e.g. 'hsla(27, 88.99%, 81.83%, 0.6450211517512798)'
                }) // '#ffcc00'
            }}),
            DropCursor.configure({color: '#7D83FF'}),
            Heading.configure({levels: [1, 2, 3, 4, 5]}),
            PMImage.configure({draggable: true}),
            PMMention.configure({HTMLAttributes: {class: 'mention',}, suggestion: Crossref }),
            Placeholder.configure({
                emptyEditorClass: 'is-editor-empty',
                showOnlyCurrent: false,
                includeChildren: true,
                placeholder: ({node}) => {
                    if (node.type.name === 'figcaption') {
                        return 'Enter caption'
                    }
                }
            }),
            Table.configure({
                resizable: true,
            }),
            TableFigure,
            TableRow,
            TableHeader,
            TableCell,
            TrackTop,
            UniqueID.configure({types: allTypes}),
        ],
    })

    editor.provider = provider
    return editor

}

export default {createEditor}
