import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import {Decoration, DecorationSet} from 'prosemirror-view'

function topDeco(doc) {
    let decos = []
    doc.descendants((node, pos) => {
        const topDecoration = Decoration.node(pos, pos+node.nodeSize,
            {class: 'top-node', 'data-top-node': 'true'})
        decos.push(topDecoration)
        return false
    })
    return DecorationSet.create(doc, decos)
}

export default Extension.create({
    name: 'trackTopLevel',
    priority: 10000,
    addOptions() {
        return {
            ...this.parent?.(),
        }
    },
    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('tracktop'),
                state: {
                    init(_, {doc}) { return topDeco(doc) },
                    apply(tr, old) { return tr.docChanged ? topDeco(tr.doc) : old}
                },
                props: {
                    decorations(state) { return this.getState(state) }
                }
            }),
        ]
    },
})
