<template>
    <TippyWidget
        class="tippy-button"
        interactive
        :animate-fill="false"
        placement="bottom"
        :distance="0"
        theme="light"
        animation="fade"
        trigger="click"
        :flip-on-update="true"
        :append-to="() => document.getElementsByClassName('pm-editor')[0]"
    >
        <template #trigger>
            <button class="button">
                <span class="icon">
                    <font-awesome-icon
                        icon="fa-solid fa-circle-chevron-down"
                        size="lg"
                    />
                </span>
            </button>
        </template>
        <div class="dropdown is-active">
            <div
                id="dropdown-menu"
                class="dropdown-menu"
                role="menu"
            >
                <div class="dropdown-content">
                    <a
                        class="dropdown-item"
                        @click="editor.chain().focus().addRowBefore().run()"
                    >
                        Add Row Before
                    </a>
                    <a
                        href="#"
                        class="dropdown-item"
                        @click="editor.chain().focus().addRowAfter().run()"
                    >
                        Add Row After
                    </a>
                    <a
                        href="#"
                        class="dropdown-item"
                        @click="editor.chain().focus().deleteRow().run()"
                    >
                        Delete Row
                    </a>
                    <hr class="dropdown-divider">
                    <a
                        class="dropdown-item"
                        @click="editor.chain().focus().addColumnBefore().run()"
                    >
                        Add Column Before
                    </a>
                    <a
                        href="#"
                        class="dropdown-item"
                        @click="editor.chain().focus().addColumnAfter().run()"
                    >
                        Add Column After
                    </a>
                    <a
                        href="#"
                        class="dropdown-item"
                        @click="editor.chain().focus().deleteColumn().run()"
                    >
                        Delete Column
                    </a>
                    <!--                    <hr class="dropdown-divider">-->
                    <!--                    <a href="#" class="dropdown-item" @click="editor.chain().focus().toggleHeaderColumn().run()">-->
                    <!--                        Toggle Header Column-->
                    <!--                    </a>-->
                    <!--                    <a href="#" class="dropdown-item" @click="editor.chain().focus().toggleHeaderRow().run()">-->
                    <!--                        Toggle Header Row-->
                    <!--                    </a>-->
                    <!--                    <a href="#" class="dropdown-item" @click="editor.chain().focus().toggleHeaderCell().run()">-->
                    <!--                        Toggle Header Cell-->
                    <!--                    </a>-->
                </div>
            </div>
        </div>
    </TippyWidget>
</template>

<script>
export default {
    name: 'PapermillTablePopupMenu',
    props: ['editor'],
    data() {
        return {
            timer: 0
        }
    },
    computed: {
        document() {
            return document
        },
        editorDiv() {
            return document.getElementsByClassName('editor-container')[0]
        }
    }
}
</script>

<style lang="scss">
.tippy-tooltip {
    background-color: transparent;
}
</style>

<style lang="scss" scoped>
.button {
    border: none;
    height: auto;
    width: auto;
    padding: 0;
    :hover {
        color: $primary;
        border: none;
    }
    :active {
        color: darken($primary, 20%);
        border: none;
    }
}
.tippy-button {
    position: absolute;
    right: 8px;
    display: none;
    top: 1px;
}
.dropdown-menu {
    text-align: left;
    .dropdown-item {
        font-size: 0.7rem;
    }
}
</style>
