import {db} from '@/db.js'
import _ from 'lodash'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export function setLastDocumentId(userId, lastDocumentId) {
    let profileRef = db.collection('users').doc(userId)
    return profileRef.set({
        lastDocumentId
    }, {merge: true})
}

export function createDocument(userId) {
    let profileRef = db.collection('users').doc(userId)
    let docRef = db.collection('docs').doc()
    let batch = db.batch()
    batch.set(docRef, {
        name: 'New Document',
        roles: {
            [userId]: 'owner'
        }
    })
    batch.set(profileRef,
        {
            docs: {
                [docRef.id]: docRef
            },
        },
        { merge: true }
    )
    return batch.commit().then(() => {
        return docRef.id
    })
}

export function deleteDocument(userId, docId) {
    let profileRef = db.collection('users').doc(userId)
    // let docRef = db.collection('docs').doc(docId)
    let batch = db.batch()
    // For now: soft delete by removing from doc list only
    // batch.delete(docRef)
    batch.set(profileRef,
        {
            docs: {
                [docId]: firebase.firestore.FieldValue.delete()
            },
        },
        { merge: true }
    )
    return batch.commit()
}

export function renameDocument(docId, name) {
    let docRef = db.collection('docs').doc(docId)
    return docRef.set({
        name
    }, {merge: true})
}

export function setPlaceholder(docId, key, val) {
    let docRef = db.collection('docs').doc(docId)
    return docRef.set({
        placeholders: {
            [key]: val
        }
    }, {merge: true})
}

export function setEditor(docId, userId, isEditor) {
    let docRef = db.collection('docs').doc(docId)
    let editorValue = undefined
    if (isEditor) {
        editorValue = 'editor'
    } else {
        editorValue = firebase.firestore.FieldValue.delete()
    }
    return docRef.set({
        roles: {
            [userId]: editorValue
        }
    }, {merge: true})
}

export function createStyle(userId, oldStyle, newStyleName) {

    let newStyle = _.cloneDeep(oldStyle)
    newStyle.name = newStyleName
    newStyle.readOnly = false

    let profile = db.collection('users').doc(userId)
    let styleRef = db.collection('styles').doc()

    // Add the new style, then add to the user's profile
    return styleRef.set(newStyle)
        .then(() => {
            return profile.update({
                [`styles.${styleRef.id}`]: styleRef
            })
                .then(() => {
                    return styleRef.id
                })
        })

}

export function setLastStyleId(userId, docId, lastStyleId) {
    let doc = db.collection('docs').doc(docId)
    return doc.set({
        lastStyleId
    }, {merge: true})
}

export function deleteStyle(userId, styleId) {
    let styleRef = db.collection('styles').doc(styleId)
    let profileRef = db.collection('users').doc(userId)
    return profileRef.update({
        [`styles.${styleId}`]: firebase.firestore.FieldValue.delete()
    }).then(() => styleRef.delete())
}

export function setFont(styleId, textStyleName, font) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        styles: {
            [textStyleName]: {
                font
            }
        }
    },{merge: true})
}

export function setFontSize(styleId, textStyleName, fontSize) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        styles: {
            [textStyleName]: {
                fontSize
            }
        }
    },{merge: true})
}

export function setFontLineHeight(styleId, textStyleName, lineHeight) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        styles: {
            [textStyleName]: {
                lineHeight
            }
        }
    },{merge: true})
}

export function setFontColor(styleId, textStyleName, color) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        styles: {
            [textStyleName]: {
                color
            }
        }
    },{merge: true})
}

export function setCapitalisation(styleId, textStyleName, capitalisation) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        styles: {
            [textStyleName]: {
                capitalisation
            }
        }
    },{merge: true})
}


export function setShowSectionNumbers(styleId, showSectionNumbers) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        sections: {
            showSectionNumbers
        }
    },{merge: true})
}

export function setListType(styleId, listType) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        listSettings: {
            type: listType
        }
    },{merge: true})
}

export function setListBulletColor(styleId, bulletColor) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        listSettings: {
            bulletColor
        }
    },{merge: true})
}

export function setListLineHeight(styleId, lineHeight) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        listSettings: {
            lineHeight
        }
    },{merge: true})
}

export function setListIndent(styleId, indent) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        listSettings: {
            indent
        }
    },{merge: true})
}

export function setFooterPageNumberType(styleId, pageNumberType) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        footer: {
            pageNumberType
        }
    },{merge: true})
}
export function setFooterPageNumberAlignment(styleId, pageNumberAlignment) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        footer: {
            pageNumberAlignment
        }
    },{merge: true})
}
export function setFooterShowPageNumber(styleId, showPageNumber) {
    let styleRef = db.collection('styles').doc(styleId)
    return styleRef.set({
        footer: {
            showPageNumber
        }
    },{merge: true})
}

export default {
    setLastDocumentId,
    createDocument,
    deleteDocument,
    renameDocument,
    setLastStyleId,
    deleteStyle,
    setCapitalisation,
    setEditor,
    setFont,
    setFontSize,
    setFontLineHeight,
    setFontColor,
    setListBulletColor,
    setListIndent,
    setListType,
    setListLineHeight,
    setFooterPageNumberType,
    setFooterPageNumberAlignment,
    setFooterShowPageNumber,
    setShowSectionNumbers,
    setPlaceholder
}
