<template>
    <node-view-wrapper class="wrapper">
        <span
            :class="{'deleted-reference': Object.values(anchorMap).length > 0 &&
                !(node.attrs.linkDestinationId in anchorMap)}"
            data-type="mention"
            data-id="Papermill Cross Reference"
            class="mention"
            contenteditable="false"
        >
            {{ label }}
        </span>
    </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import {mapState} from 'vuex'
export default {
    components: {
        NodeViewWrapper
    },
    props: nodeViewProps,
    computed: {
        ...mapState(['anchorMap']),
        label() {  //set length on loading
            if (this.node.attrs.linkDestinationId in this.anchorMap) {
                const anchor = this.anchorMap[this.node.attrs.linkDestinationId]
                return anchor.label ? anchor.label : anchor.id
            } else if (this.anchorMap) { // check not loading
                return 'The target of this reference has been removed'
            } else {
                return '' // loading
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    display: inline-block;
}
</style>

<style>
.deleted-reference {
    background-color: red !important;
    color: white !important;
}
</style>
