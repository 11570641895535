<template>
    <node-view-wrapper class="pm-img-wrapper">
        <div>
            <button
                class="button delete-button"
                @click="deleteImage"
            >
                <span class="icon">
                    <font-awesome-icon
                        icon="fa-regular fa-trash-can"
                        size="lg"
                    />
                </span>
            </button>
            <div
                class="img-container"
                :class="{noimg: !src, dropTarget: !src, dragging}"
                @drop="onDrop"
                @dragover.prevent="dragging = true"
                @dragleave="dragging = false"
            >
                <!-- Drop Images Here label -->
                <div
                    v-if="!src"
                    class="label"
                />
                <div
                    v-else
                    class="inner-container"
                >
                    <img
                        v-if="src"
                        ref="img"
                        :src="src"
                        alt=""
                        class="pmimg"
                    >
                    <progress
                        v-if="uploading"
                        class="progress upload-progress-bar"
                        :value="uploadProgress"
                        max="100"
                    />
                    <div class="width-container">
                        <div class="width-label">
                            Width (% of text width)
                        </div>
                        <div class="width-slider-container">
                            <vue-slider
                                :value="sliderValue"
                                :lazy="true"
                                :tooltip-formatter="tooltipFormatter"
                                @change="changeWidth"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import {mapState} from 'vuex'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import { NodeViewWrapper,  } from '@tiptap/vue-2' //NodeViewContent
import { v4 as uuidv4 } from 'uuid'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { app } from '@/main'
import {toast} from 'bulma-toast'

export default {
    props: {
        node: {
            type: Object,
            required: true,
        },
        updateAttributes: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            uploading: false,
            dragging: false,
            src: undefined,
            uploadProgress: 0,
            sliderValue: undefined,
            tooltipFormatter: '{value}%',
        }
    },
    computed: {
        imgId() {
            return this.node.attrs.imgId
        },
        title() {
            return this.node.attrs.title
        },
        alt() {
            return this.node.attrs.alt
        },
        width() {
            return this.node.attrs.layoutWidth
        },
        ...mapState(['currentDocument'])
    },
    components: {
        NodeViewWrapper,
        VueSlider
    },
    watch: {
        imgId: {
            handler(id) {
                if (this.currentDocument && id) {
                    let storageRef = firebase.storage().ref()
                    let fileRef = storageRef.child(`docs/${this.currentDocument.id}/images/${id}`)
                    fileRef.getDownloadURL()
                        .then((url) => {
                            this.src = url
                        })
                }
            },
            immediate: true
        },
        width: {
            handler(newWidth) {
                this.sliderValue = newWidth
            },
            immediate: true
        }
    },
    methods: {
        deleteImage() {
            this.deleteNode()
            let pos = this.editor.state.doc.resolve(this.getPos())
            for (let depth = pos.depth; depth > 0; depth -= 1) {
                const node = pos.node(depth)  // get its parent, <shrug>
                if (node.type.name === 'imageFigure') {
                    const from = pos.before(depth)
                    const to = pos.after(depth)
                    this.editor.commands.deleteRange({from, to})
                }
            }
        },
        //tr.delete(from, to).scrollIntoView()
        changeWidth(layoutWidth) {
            this.updateAttributes({
                layoutWidth
            })
        },
        onDrop: async function(e) {

            app.$log.debug('Image dropped', e.dataTransfer.files[0])

            this.dragging = false

            // If we already have an image, ignore the drop
            if (this.src) return

            // Stop drop from opening a new browser tab
            e.stopPropagation()
            e.preventDefault()

            let file = e.dataTransfer.files[0]
            if (!file.type.match('image.*')) {
                toast({
                    message: 'File type is not recognised - not an image file',
                    type: 'is-danger',
                    duration: 3000,
                    position: 'bottom-center'
                })
                return
            }

            // Load into UI
            const captureThis = this
            let reader = new FileReader()

            // Read file, update image on page
            reader.onload = function() {

                // Set UI src for immediate effect whilst uploading
                captureThis.src = reader.result

                // Separately Load image to get width and height. Then upload.
                let image = new Image()
                image.onload = function() {
                    const imgWidth = image.naturalWidth
                    const imgHeight = image.naturalHeight
                    captureThis.uploadImage(captureThis.currentDocument.id, file, imgWidth, imgHeight)
                }
                image.src = reader.result
            }

            await reader.readAsDataURL(file)

        },

        // Save image to firebase
        async uploadImage(docId, file, width, height) {
            let imgId = uuidv4()
            let storageRef = firebase.storage().ref()
            let fileRef = storageRef.child(`docs/${docId}/images/${imgId}`)
            let captureThis = this
            try {
                this.uploading = true
                let uploadTask = fileRef.put(file)
                uploadTask.on('state_changed',
                              (snapshot) => {
                                  // Observe state change events such as progress, pause, and resume
                                  captureThis.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                              },
                              (error) => {
                                  toast({
                                      message: `Error uploading ${error}`,
                                      type: 'is-danger',
                                      duration: 3000,
                                      position: 'bottom-center'
                                  })
                              },
                              () => {
                                  // Success
                                  this.uploading = false
                                  // Use tiptap callback to set id
                                  captureThis.updateAttributes({
                                      imgId,
                                      width,
                                      height
                                  })
                              }
                )
            } catch (err) {
                toast({
                    message: 'Failed to upload image ' + err,
                    type: 'is-danger',
                    duration: 3000,
                    position: 'bottom-center'
                })
                this.uploading = false
                return
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.pm-img-wrapper {
    position: relative;
}
.delete-button {
    position: absolute;
    right: -2rem;
    top: -1rem;
    border: none;
    background-color: transparent;
    color: darkgrey;
    display: none;
}
.delete-button:active {
    color: grey;
}

.label {
    user-select: none;
}
.label::before {
    content: "Drop Image Here"
}
</style>
<style lang="scss">
.width-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.width-label {
    font-family: Lato, sans-serif;
    font-size: 0.7rem;
    margin-bottom: -0.5rem;
}
.vue-slider-process {
    background-color: $primary !important;
}
.vue-slider-dot-handle {
    border-color: $primary !important;
}
.vue-slider-dot-handle-focus {
    box-shadow: 0 0 0 5px rgba($primary, .2); //rgb(54 171 255 / 20%)
}
</style>
<style scoped>
.img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container.dragging {
    border: solid black 2px !important;
}

.img-container.noimg {
    border: dashed grey 1px;
    height: 10rem;
}
</style>
