import store from '@/store/'
import {EventBus} from '@/event-bus'

function truncateToLength(text, maxLength) {
    if (text.length > maxLength) {
        return text.slice(0, maxLength-3) + '...'
    } else {
        return text
    }
}

export function updateHandler(args) {
    EventBus.$emit('documentChanged')
    updateTopLevelNodes(args)
    updateAnchorMapAndOutline(args)
}

export function updateTopLevelNodes({editor}) {
    let doc = editor.state.doc
    let topLevelNodes = {}
    doc.descendants((node) => {
        topLevelNodes[node.attrs.id] = true
        return false
    })
    store.commit('setTopLevelNodes', topLevelNodes)
}

export function buildAnchorMapAndOutline(editor) {
    let json = editor.getJSON()
    let fig = 0
    let table = 0
    let section = [0,0,0,0,0]
    let anchors = {}
    let outline = []
    for (let block of json.content) {
        if (block.type === 'heading') {
            for (let i=0; i < section.length; i++) {
                if (i === block.attrs.level-1) {
                    section[i] = section[i] + 1
                } else if (i >= block.attrs.level) {
                    section[i] = 0
                }
            }
            let sectionNumber = section.slice(0, block.attrs.level)
            let title = block.content? block.content[0].text : ''
            let sectionHeading
            if (block.attrs.numbered) {
                sectionHeading = 'Section ' + sectionNumber.join('.') + ': ' + title
            } else {
                sectionHeading = title
            }
            let truncatedSectionHeading = truncateToLength(sectionHeading, 60)
            let anchor = {
                type: block.type,
                label: truncatedSectionHeading,
                id: block.attrs.id,
                level: block.attrs.level,
                sectionNumbers: [...section],
                numbered: block.attrs.numbered,
                title
            }
            anchors[block.attrs.id] = anchor
            outline.push(anchor)
        } else if (block.type === 'imageFigure') {
            fig += 1
            let figureHeading = `Fig. ${fig}${block.content[1].content ? ': ' + block.content[1].content[0].text : ''}`
            let truncatedFigureHeading = truncateToLength(figureHeading, 60)
            let anchor = {
                id: block.attrs.id,
                figureNumber: fig,
                label: truncatedFigureHeading,
                type: block.type,
            }
            anchors[block.attrs.id] = anchor
            outline.push(anchor)
        } else if (block.type === 'tableFigure') {
            table += 1
            let tableHeading = `Table ${table}${block.content[1].content ? ': ' + block.content[1].content[0].text : ''}`
            let truncatedTableHeading = truncateToLength(tableHeading, 60)
            let anchor = {
                id: block.attrs.id,
                tableNumber: table,
                label: truncatedTableHeading,
                type: block.type,
            }
            anchors[block.attrs.id] = anchor
            outline.push(anchor)
        }
    }
    return {anchors, outline}
}

export function updateAnchorMapAndOutline({editor}) {
    let {anchors, outline} = buildAnchorMapAndOutline(editor)
    store.commit('setAnchorMap', anchors)
    store.commit('setOutline', outline)
}

export default {updateAnchorMapAndOutline, updateHandler}
