<template>
    <div
        class="document-name-container"
        :class="{hasFocus}"
    >
        <input
            ref="nameBox"
            v-model="docName"
            type="text"
            class="document-name"
            placeholder="Click to name document"
            @autocomplete="false"
            @blur="onBlurDocName"
            @change="renameDoc"
            @focus="onFocusDocName"
            @keydown.escape="onEscape"
            @keydown.enter="onEnter"
        >
        <span
            class="icon is-small edit-icon"
            :class="{hide: hasFocus}"
            @click="onClickEditIcon"
        >
            <font-awesome-icon icon="fa-light fa-pen" />
        </span>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import dal from '@/dal'
import {toast} from 'bulma-toast'

export default {
    data() {
        return {
            docName: '',
            hasFocus: false
        }
    },
    computed: {
        ...mapState(['currentDocument', 'editor'])
    },
    watch: {
        currentDocument: {
            immediate: true,
            handler(newDoc) {
                if (newDoc) {
                    this.docName = newDoc.name
                    // Workaround for Firefox's behaviour
                    // See https://bugzilla.mozilla.org/show_bug.cgi?id=860329
                    if (this.$refs && this.$refs.nameBox) {
                        this.$refs.nameBox.scrollLeft = 0
                    }
                }
            }
        }
    },
    mounted() {
        // Workaround for Firefox's behaviour
        // See https://bugzilla.mozilla.org/show_bug.cgi?id=860329
        this.$refs.nameBox.scrollLeft = 0
    },
    methods: {
        renameDoc() {
            dal.renameDocument(this.currentDocument.id, this.docName)
                .catch((err) => {
                    toast({
                        message: `Error - failed to rename document ${err.message}`,
                        type: 'is-danger',
                        duration: 3000,
                        position: 'bottom-center'
                    })
                })
        },
        // When edit icon is clicked
        onClickEditIcon() {
            this.$refs.nameBox.focus()
            this.$refs.nameBox.select()
        },
        onBlurDocName() {
            this.hasFocus = false
            // Workaround for Firefox's behaviour
            // See https://bugzilla.mozilla.org/show_bug.cgi?id=860329
            this.$refs.nameBox.scrollLeft = 0
        },
        onEscape() {
            // Accessibility dictates not to use blur, but instead to set focus elsewhere
            this.editor.commands.focus()
        },
        onEnter() {
            // Accessibility dictates not to use blur, but instead to set focus elsewhere
            this.editor.commands.focus()
        },
        onFocusDocName() {
            this.hasFocus = true
        }
    }
}
</script>

<style lang="scss" scoped>
.document-name-container {
    margin-left: 0.1rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    &:hover {
        .edit-icon:not(.hide) {
            color: lightgrey;
        }
    }
    .document-name {
        width: 40ch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background-color: transparent;
        color: white;
        font-family: Lato, sans-serif;
        font-weight: 400;
        font-size: 14px;
        border: 1px solid transparent;
        ::placeholder {
            color: lightgrey;
        }
        &:hover {
            outline: 1px solid lightgray;
        }
    }
    .edit-icon {
        display: inline-flex;
        align-items: center;
        color: transparent;
        margin-left: 0.5rem;
        &:hover {
            color: whitesmoke !important;
            &.hide {
                color: transparent !important;
            }
        }
        &:active {
            color: lightgrey !important;
        }
    }
}
</style>
