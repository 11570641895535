import {
    Node,
    nodeInputRule,
    mergeAttributes,
} from '@tiptap/core'

import { VueNodeViewRenderer } from '@tiptap/vue-2'
import PMImage from '../components/PapermillImage.vue'

export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/

export default Node.create({

    name: 'image',
    group: 'block',

    // content: 'inline*',

    addOptions() {
        return {
            inline: false,
            allowBase64: false,
            HTMLAttributes: {},
        }
    },

    addKeyboardShortcuts() {
        return {
            // 'Delete': () => console.log('DELETE')
        }
    },

    inline() {
        return this.options.inline
    },

    draggable: false,
    selectable: false,

    addAttributes() {
        return {
            imgId: {
                default: null
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            layoutWidth: {
                default: 80
            },
            width: {
                default: null
            },
            height: {
                default: null
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: this.options.allowBase64
                    ? 'img[src]'
                    : 'img[src]:not([src^="data:"])',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div',
            ['span', {class: 'no-select block-label', 'contenteditable': 'false'}, 'img'],
            ['vue-component', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
        ]
    },

    addNodeView() {
        return VueNodeViewRenderer(PMImage)
    },

    addCommands() {
        return {
            setImage: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },

    addInputRules() {
        return [
            nodeInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: match => {
                    const [,, alt, src, title] = match

                    return { src, alt, title }
                },
            }),
        ]
    },
})
