import {NodeSelection} from 'prosemirror-state'

const quotes = [
    '"There is nothing to writing. All you do is sit down at a typewriter and bleed."',
    '"One day I will find the right words, and they will be simple."',
    '"The scariest moment is always just before you start."'
]

function setAttributes(element, attributes) {
    Object.keys(attributes).forEach(attr => {
        element.setAttribute(attr, attributes[attr])
    })
}

// Tag is the main content tag, e.g. "h1" or "figure"
// Parent tag is optional, e.g. tag='code' and parentTag='pre' will produce <pre><code>...
// Block label is displayed to left of block in UI
export default function createNodeView(tag, blockLabel, parentTag, extraHTMLAttributes) {
    return ({editor, getPos, node, decorations, HTMLAttributes}) => {
        // Is this currently a top-level node, as determined by decoration?
        let topNode = decorations.some(deco => deco.type.attrs['data-top-node'])
        tag = typeof tag === 'function' ? tag({node}): tag
        // Convenient
        const view = editor.view
        // Create the actual tag, needs to be node-content, undraggable, add placeholder quote to data-placeholder prop
        // And set data-id prop to be the tiptap block id (useful for navigation to anchors etc)
        const contentDOM = document.createElement(tag)
        contentDOM.classList.add('node-content')
        contentDOM.draggable = false
        contentDOM.dataset.placeholder = quotes[Math.floor(Math.random() * quotes.length)]
        contentDOM.dataset.id = node.attrs.id
        contentDOM.dataset.nodeType = node.type.name
        setAttributes(contentDOM, HTMLAttributes)
        if (extraHTMLAttributes) {
            setAttributes(contentDOM, extraHTMLAttributes)
        }
        if (node.attrs.language) {
            setAttributes(contentDOM, {'data-language': node.attrs.language})
        }
        // Top Nodes go into a container that holds label, drag handle, and the block itself
        if (topNode) {
            blockLabel = typeof blockLabel === 'function' ? blockLabel({node}) : blockLabel
            const label = document.createElement('div')
            label.contentEditable = 'false'
            label.classList.add('block-label')
            label.draggable = false
            label.dataset.nodeLabel = blockLabel
            const handleContainer = document.createElement('div')
            const dom = document.createElement('div')
            dom.classList.add('papermill-node')
            dom.classList.add(tag)
            dom.draggable = false
            handleContainer.contentEditable = 'false'
            handleContainer.classList.add('drag-handle')
            handleContainer.onmousedown = e => {
                if (e.button === 0) {
                    view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, getPos())))
                }
            }

            if (parentTag) {
                let parent = document.createElement(parentTag)
                parent.append(contentDOM)
                dom.append(label, handleContainer, parent)
            } else {
                dom.append(label, handleContainer, contentDOM)
            }
            return {
                dom,
                contentDOM
            }
        } else {
            if (parentTag) {
                let dom = document.createElement(parent)
                dom.append(contentDOM)
                return {
                    dom,
                    contentDOM
                }
            } else {
                return {
                    contentDOM
                }
            }
        }
    }
}
